// @ts-nocheck
"use client";

import FAQ from "@/components/landing/Faqs";
import Header from "@/components/landing/Header";
import Image from "next/image";

import { Footer } from "@/components/landing/Footer";
import { useEffect, useState } from "react";
import { getRGBColor } from "@/utils";
import { getHost } from "@/lib/actions";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import LoadingScreen from "@/components/common/LoadingScreen";

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [jurisdiction, setJurisdiction] = useState({});

  useEffect(() => {
    const getHeader = async () => {
      const host = await getHost();
      const { jurisdiction } = await (
        await fetch(`/api/jurisdiction/${host}`, { next: { revalidate: 120 } })
      ).json();
      const primary_color = getRGBColor(
        "primary",
        jurisdiction.branding.colors.primary,
      );
      const secondary_color = getRGBColor(
        "secondary",
        jurisdiction.branding.colors.secondary,
      );
      document.documentElement.style.setProperty(
        primary_color.key,
        primary_color.value,
      );
      document.documentElement.style.setProperty(
        secondary_color.key,
        secondary_color.value,
      );

      setJurisdiction(jurisdiction);
      setIsLoading(false);
    };

    getHeader();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!jurisdiction || Object.values(jurisdiction).length === 0) {
    return <div>Theres no jurisdiction info</div>;
  }

  return (
    <div>
      <Header
        name={jurisdiction?.name}
        logo={jurisdiction?.branding?.logo?.image}
      />
      <div className="relative isolate overflow-hidden">
        <div className="absolute sm:-mr-80 lg:-mr-96" aria-hidden="true" />
        <div className="mx-auto w-full bg-[#f1f6f8]">
          <div className="mx-auto max-w-7xl px-7 pb-12 pt-20 lg:px-8 lg:pt-10">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex w-full justify-between">
                <div className="mx-auto flex flex-col justify-center lg:mx-0">
                  <h1 className="mb-4 max-w-md break-words text-center text-5xl font-bold tracking-tight text-primary md:text-6xl lg:text-left">
                    {jurisdiction?.name.endsWith("s") ? (
                      <>Welcome to the {jurisdiction.name} alarm center</>
                    ) : (
                      <>
                        Welcome to the {jurisdiction.name}&apos;s alarm center
                      </>
                    )}
                  </h1>
                  <div className="mt-6 flex max-w-xl flex-col items-center lg:mt-0 lg:items-start">
                    <p className="mb-2 max-w-sm text-center text-lg leading-8 text-gray-600 lg:max-w-xs lg:text-left">
                      Login to view account information such as alarm history,
                      payment history, current balance, etc.
                    </p>
                    <div className="mt-10 flex justify-center lg:justify-start">
                      <Link href="/onboarding">
                        <Button>Get Started</Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="relative hidden max-w-lg lg:block">
                  <Image
                    alt="image of files with lines"
                    src="/files.svg"
                    width="0"
                    height="0"
                    className="h-full w-full object-cover"
                    placeholder="blur"
                    blurDataURL="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8+O3HfwAJnwPgEZjubwAAAABJRU5ErkJggg=="
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
      <FAQ entries={jurisdiction?.faqEntries?.data || []} />
      <Footer />
    </div>
  );
}
