// @ts-nocheck
import { Container } from "@/components/landing/Container";
import Image from "next/image";
import Link from "next/link";

export function Footer() {
  return (
    <footer className="bg-primary">
      <Container>
        <div className="py-12 lg:py-24">
          <div
            id="Contact"
            className="w-full text-center text-2xl font-extrabold text-gray-50 lg:text-left"
          >
            Contact us
          </div>
          <div className="mt-12 flex flex-col gap-y-4 lg:flex-row">
            <div className="mr-36 text-gray-50">
              <div className="text-xl font-bold">Email</div>
              <div className="mt-3 text-sm">
                <a href="mailto:support@munipal.io">support@munipal.io</a>
              </div>
            </div>
            <div className="h-[1px] w-full bg-gray-500 lg:hidden"></div>
            <div className="mr-36 text-gray-50">
              <div className="text-xl font-bold">Phone number</div>
              <div className="mt-3 text-sm">
                <a href="tel:+18007499669">1 800 749 9669</a>
              </div>
            </div>
            <div className="h-[1px] w-full bg-gray-500 lg:hidden"></div>
            <div className="text-gray-50">
              <div className="text-xl font-bold">Address</div>
              <div className="mt-3 text-sm">
                P.O. Box 460219 Escondido, CA 92026
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center py-10 sm:flex-row-reverse sm:justify-between">
          <Link
            href="https://munipal.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex-row">
              <span className="pr-2 text-sm text-gray-50">Powered by</span>
              <Image
                className="inline"
                alt="Munipal Logo"
                src="/munipal.svg"
                width={111}
                height={24}
              />
            </div>
          </Link>
          <div>
            <p className="mt-6 text-sm text-gray-50 sm:mt-0">
              &copy; 2018-{new Date().getFullYear()} Municpal Alarm Tracking
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}
