import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { cacheExchange, createClient, fetchExchange, gql } from "@urql/core";
import { registerUrql } from "@urql/next/rsc";
import { LobData } from "./types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function createTokenUrqlClient(token: String | null) {
  const makeClient = () => {
    return createClient({
      url: process.env.NEXT_PUBLIC_GRAPHQL_URL as string,
      exchanges: [cacheExchange, fetchExchange],
      fetchOptions: {
        // @ts-ignore
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  };

  return registerUrql(makeClient);
}

// To be used in queries that don't require a user token
export function createGuestUrqlClient() {
  const makeClient = () => {
    return createClient({
      url: process.env.NEXT_PUBLIC_GRAPHQL_URL as string,
      exchanges: [cacheExchange, fetchExchange],
      fetchOptions: {
        // @ts-ignore
        headers: {
          "x-api-key": process.env
            .NEXT_PUBLIC_GRAPHQL_PUBLISHABLE_KEY as string,
        },
      },
    });
  };

  return registerUrql(makeClient);
}

type AddressObject = {
  primary_line: string;
  secondary_line?: string;
  city: string;
  state: string;
  zip_code: string;
};

export const validateArguments = (
  apiKey: string,
  address: string | AddressObject,
  countryCode: string | null,
  isInternational: boolean,
) => {
  if (!Object.keys(address || {}).length) {
    return new Error("Empty address was passed to verify function");
  }

  if (!apiKey.length) {
    return new Error("Missing API key");
  }

  if (isInternational) {
    if (typeof countryCode !== "string") {
      return new Error("Expected countryCode to be of type string");
    }
    if (/[A-Z]{2}/.test(countryCode) === false) {
      return new Error(
        "countryCode must be a 2 letter country short-name code (ISO 3166)",
      );
    }
  }

  return null;
};

// This function exists solely because the verify from lob doesn't support adding
// the case param. The code is almost identical.
// https://github.com/lob/react-address-autocomplete/blob/main/src/verify.js
export async function verify(apiKey: string, address: string | AddressObject) {
  const error = validateArguments(apiKey, address, null, false);
  if (error) {
    return Promise.reject(error);
  }

  let payloadAddress = address;
  // Check if the a single line address was passed as an object
  if (typeof address === "object") {
    const componentsWithValue = Object.keys(address).filter(
      // @ts-ignore
      (addressComponent) => address[addressComponent] !== "",
    );
    if (
      componentsWithValue.length === 1 &&
      componentsWithValue.includes("primary_line")
    ) {
      payloadAddress = address.primary_line;
    }
  }

  const url = new URL("https://api.lob.com/v1/us_verifications");
  url.searchParams.append("case", "proper");
  const init = {
    method: "POST",
    headers: {
      Authorization: `Basic ${btoa(apiKey + ":")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payloadAddress),
  };

  const response = await fetch(url, init);

  const jsonResponse = response.json();

  if ("error" in jsonResponse) {
    if (jsonResponse.error instanceof Error) {
      return Promise.reject(new Error(jsonResponse.error.message));
    }
  }

  return jsonResponse;
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);

  // An array to map month numbers to month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extracting the day, month, and year
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Formatting the date in Day Month Year format
  return `${day} ${month} ${year}`;
}

export function getTime(dateTimeString: string): string {
  const date = new Date(dateTimeString);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  return `${formattedHours}:${minutes} ${period}`;
}

export function formatCurrency(
  value: number,
  locale = "en-US",
  currency = "USD",
) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value / 100);
}

export function addressContainsRequiredFields(address: LobData) {
  if (
    address.deliverability == "" ||
    address.primary_line == "" ||
    !address.components ||
    address.components.city == "" ||
    address.components.state == "" ||
    address.components.zip_code == ""
  ) {
    return false;
  }

  return true;
}

export function convertToArray<T>(data: T | T[]): T[] {
  if (data === null || data === undefined) {
    return [];
  }

  return Array.isArray(data) ? data : [data];
}
