import BounceLoader from "react-spinners/BounceLoader";

export default function LoadingScreen() {
  return (
    <div
      className="flex h-screen items-center justify-center bg-[#f1f6f8]"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23004580' fill-opacity='0.15' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
      }}
    >
      <BounceLoader color="#004580" />
    </div>
  );
}
